/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import {
  Text, spacing, container, media,
} from '@comicrelief/component-library';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO/SEO';

const Header = styled.header`
  background: ${({ theme }) => theme.color('blue_dark')};
  padding: ${spacing('xl')};
  h1 {
    text-align: center;
    width: 100%;
    max-width: ${container.small};
    margin: 0 auto;
  }

  @media (max-width: ${container.small}) {
    padding: ${spacing('l')};
    h1 {
      font-size: ${({ theme }) => theme.fontSize('m')};
      line-height: ${({ theme }) => theme.fontSize('l')};
    }
  }
`;

const Container = styled.article.attrs({
  'data-test': 'sms',
})`
  background: ${({ theme }) => theme.color('white')};
  width: 100%;
  max-width: ${container.small};
  margin: ${spacing('l')} auto;
  padding: ${spacing('md')};
  ${media('small')} {
    padding: ${spacing('l')} ${spacing('xl')};
  }

  a {
    color: inherit;
    font-weight: 700;
  }

  ul,
  ol {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;

    li {
      word-break: break-word;
      :not(:last-child) {
        padding-bottom: ${spacing('md')};
      }
    }
  }

  table {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
  }

  @media (max-width: 740px) {
    h1 {font-size: ${({ theme }) => theme.fontSize('s')}}
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    table { border-bottom-width: 0}

    tbody,
    tr { border: none;}

    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    td {
      position: relative;
      display: flex;
      justify-content: flex-end;

      :not(last-child) {
        border-top: 0;
        border-color: black;
        border-left: 0;
        border-right: 0;
      }
    }

    td:before {
      position: absolute;
      top: 6px;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
    }

    tr:nth-of-type(odd) {
      background: ${({ theme }) => theme.color('grey_medium')};
    }

    td:before {
      color: #223c59;
      font-weight: bold;
    }
    td:nth-of-type(1):before {
      content: "Keyword";
    }
    td:nth-of-type(2):before {
      content: "Short code";
    }
    td:nth-of-type(3):before {
      content: "Donation Value";
    }
    td:nth-of-type(4):before {
      content: "Live Date";
    }
    td:nth-of-type(5):before {
      content: "Closing Date";
    }

    h2 {
      font-size: ${({ theme }) => theme.fontSize('s')};
      line-height: ${({ theme }) => theme.fontSize('m')};
    }
  }

  ${media('small')} {
    table {
      width: 100%;

      th {
        color: #223c59;
      }

      th {
        padding: ${spacing('md')};
      }
    }
  }
`;

const sms = () => (
  <Layout>
    <SEO pathname="sms" title="SMS | Sport Relief " />
    <Header tag="h1">
      <Text tag="h1" color="white" size="super" uppercase amily="Anton" weight="400">
        TEXT TO DONATE TERMS AND CONDITIONS
      </Text>

    </Header>
    <Container>
      <Text tag="p">
        You can donate to Sport Relief by texting the below Keyword to the relevant number (the ‘Short Code’). Each text message you send will result in a donation to Comic Relief of the amount listed in the ‘Donation Value’ column. For example, by texting “GIVE” to 70210, you will donate £10.
      </Text>

      <Text tag="p">The following Keywords and Short Codes allow donations to Sport Relief:</Text>

      <table border="1" cellPadding="1" cellSpacing="1">
        <thead>
          <tr>
            <th><strong>Keyword</strong></th>
            <th><strong>Short code</strong></th>
            <th><strong>Donation Value</strong></th>
            <th><strong>Live Date</strong></th>
            <th><strong>Closing Date</strong></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>GIVE</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>01/07/2019</p>
            </td>
            <td>
              <p>01/07/2022</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>GIVE</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>01/07/2019</p>
            </td>
            <td>
              <p>01/07/2022</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>GIVE</p>
            </td>
            <td>
              <p>70220</p>
            </td>
            <td>
              <p>£20</p>
            </td>
            <td>
              <p>01/07/2019</p>
            </td>
            <td>
              <p>01/07/2022</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>GIVE</p>
            </td>
            <td>
              <p>70230</p>
            </td>
            <td>
              <p>£30</p>
            </td>
            <td>
              <p>01/07/2019</p>
            </td>
            <td>
              <p>01/07/2022</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>YES</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>28/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>YES</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>28/02/2020</p>
            </td>
            <td>
              <p>18/01/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>YES</p>
            </td>
            <td>
              <p>70220</p>
            </td>
            <td>
              <p>£20</p>
            </td>
            <td>
              <p>28/02/2020</p>
            </td>
            <td>
              <p>18/01/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>YES</p>
            </td>
            <td>
              <p>70230</p>
            </td>
            <td>
              <p>£30</p>
            </td>
            <td>
              <p>07/02/2020</p>
            </td>
            <td>
              <p>18/01/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>BEAT</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>BEAT</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>BEAT</p>
            </td>
            <td>
              <p>70220</p>
            </td>
            <td>
              <p>£20</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>KIT</p>
            </td>
            <td>
              <p>70702</p>
            </td>
            <td>
              <p>£2</p>
            </td>
            <td>
              <p>07/01/2020</p>
            </td>
            <td>
              <p>01/01/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>KIT</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>07/01/2020</p>
            </td>
            <td>
              <p>01/01/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>KIT</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>07/01/2020</p>
            </td>
            <td>
              <p>01/01/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SPORT</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>22/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>HEAT</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>14/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>HEAT</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>14/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>HEAT</p>
            </td>
            <td>
              <p>70220</p>
            </td>
            <td>
              <p>£20</p>
            </td>
            <td>
              <p>14/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>HEAT</p>
            </td>
            <td>
              <p>70230</p>
            </td>
            <td>
              <p>£30</p>
            </td>
            <td>
              <p>14/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>SOFA</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5&nbsp;</p>
            </td>
            <td>
              <p>14/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>CLIMB</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>GARETH</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>28/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>GARETH</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>28/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>GARETH</p>
            </td>
            <td>
              <p>70220</p>
            </td>
            <td>
              <p>£20</p>
            </td>
            <td>
              <p>28/02/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>DARE</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>DARE</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>DARE</p>
            </td>
            <td>
              <p>70220</p>
            </td>
            <td>
              <p>£20</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>DARE</p>
            </td>
            <td>
              <p>70230</p>
            </td>
            <td>
              <p>£30</p>
            </td>
            <td>
              <p>06/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>TEAM</p>
            </td>
            <td>
              <p>70205</p>
            </td>
            <td>
              <p>£5</p>
            </td>
            <td>
              <p>12/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

          <tr>
            <td>
              <p>TEAM</p>
            </td>
            <td>
              <p>70210</p>
            </td>
            <td>
              <p>£10</p>
            </td>
            <td>
              <p>12/03/2020</p>
            </td>
            <td>
              <p>01/05/2021</p>
            </td>
          </tr>

        </tbody>
      </table>

      <p>&nbsp;</p>

      <ul>
        <li>As well as the relevant Donation Value, each text message will incur your standard network message charge (based on your service provider rates). This service can only be used from a UK mobile and does not include Channel Islands or Isle of Man mobile networks. If you donate using this service from a UK mobile abroad you may incur additional network charges.</li>
        <li>Where calls to donate are associated with challenges, your donations are independent of the success or failure of the challenge.</li>
        <li>The following mobile network operators have waived their charges in order to allow the full Donation Value of the reply confirmation text message (described below) to go entirely to support Comic Relief: 3, O2, EE, Sky, Virgin and Vodafone. In addition, HMRC has confirmed that no VAT should be charged on these donations.</li>
        <li>You can send a text message between 10:00 on the date the Keyword and Short Code combination go ‘Live’, as listed in the above table, and 23.59 on the Closing Date, as listed in the above table. Please ensure you spell the Keyword correctly and send your text message to the correct Short Code, however, if we, acting reasonably believe you are trying to donate, we will still take the donation even if you entered the Keyword incorrectly.</li>
        <li>If your Keyword and Short Code are valid and your text message is received within the specified times and dates, you will be charged the applicable Donation Value via reply confirmation text message(s) and your donation will go to Comic Relief (with the exception of Vodafone customers, who will be charged the Donation Value directly via their bill). It may take some time for you to receive the confirmation text message. Please wait until you receive it. Unless a cap applies (see below) each valid text you send will result in a charge of the Donation Value plus your standard network message charge, regardless of when you receive your confirmation text message. Any repeat texts will result in repeat donations.</li>
        <li>If you are a UK taxpayer, we can claim an additional 25p in Gift Aid from the Government for every £1 you donate. And it doesn't cost you a penny - just give us your details! To help us claim this, we may send you a follow up text to ask if you want to claim Gift Aid on your donation. For us to claim Gift Aid, you must pay UK income or capital gains tax this year (this does not include VAT or council tax) at least equal to the amount reclaimed on all your charitable donations. Find out more about Gift Aid here. We may include a link in the text message, and if you follow the link you will be taken to a website to complete the Gift Aid declaration. Please read the details carefully before submission. You will not automatically be added to any marketing databases if we send you this follow up text.</li>
        <li>Your information will be collected by Fonix Mobile Limited on behalf of Charity Projects (operating as Comic Relief) and will only be used for processing your donation, providing receipts, and in order to help analyse our income. It will not be used for any other purposes. Any additional information provided as part of the Gift Aid declaration will only be used for the purpose of claiming Gift Aid. Your data will only be used by Comic Relief and our agents and will NOT be passed onto any third parties. Please see Comic Relief’s Privacy Policy here.</li>
        <li>If your Keyword or Short Code is invalid, or if your message is received outside the times and dates of the service, you may not receive a confirmation text message but may still be charged your standard network message charge. If no confirmation text message is received, you will not be charged the Donation Value as you will not be donating to the charity. However, if you receive the confirmation text message then you may still be charged the Donation Value, in which case your donation will still be made to Comic Relief.</li>
        <li>You can donate by text to each Short Code up to a maximum total donation of £40 each day from the same mobile telephone number (midnight to midnight). If you try to donate more than that in a day using the same Keyword from the same mobile, you will still be charged your standard network rate, but will not be charged the Donation Value for a confirmation text message and will not be donating to Comic Relief. For the avoidance of doubt this maximum is placed on the text messages sent with the same Keyword to the same Short Code.</li>
        <li>If you would like to contribute more money to Comic Relief, you can donate online. Any donations to Comic Relief, including donations via text, are non-refundable.</li>
        <li>All supporters must obtain permission from the bill payer before sending a text message. If you are under 16 years old, you must have your parent or guardian’s permission to send a text donation to us.</li>
        <li>Comic Relief, BBC and their sub-contractors, subsidiaries and/or agencies cannot accept any responsibility whatsoever for any technical failure or malfunction or any other problem with any telephone network or line, system, server, provider or otherwise which may result in any text being lost or not properly received.</li>
        <li>To find out more about the charity Comic Relief, or its initiative Comic Relief, or if you have questions about this service, please go to www.comicrelief.com or call the Comic Relief Reception on 020 7820 2000 (Monday to Friday 9:00 – 17:00). Standard geographic charges from landlines and mobiles will apply and calls may be included in your telecom provider’s call package.</li>
        <li>The text service is provided by Fonix Mobile Limited. If you have any queries regarding the service, please contact Fonix at the following email address: care@fonix.com or write to Fonix Mobile Limited, 23 Heddon Street, London, W1B 4BQ or call on the following Helpline Number: 0208 114 7001 (Monday to Friday 9:00 - 17:30).</li>
        <li>Comic Relief is an initiative of Charity Projects, operating as Comic Relief, a registered charity, number 326568 (England and Wales) SC039730 (Scotland).</li>
        <li>Comic Relief reserves the right to change these terms and conditions at any time. Any updates will be posted on this website so please check back regularly. The governing law is that of England and Wales.</li>
      </ul>

      <p>&nbsp;</p>
      <h2>“Bond: No Time to Die” Free Prize Draw for Sport Relief</h2>
      <h3>Text to Win Terms and Conditions </h3>
      <ol>
        <li>The prize draw will be open at 6.30pm on 13th March 2020 and close at 2pm on 25th March 2020. </li>
        <li>
          Entry to the prize draw is only by texting the keyword
          <strong> BOND</strong>
          {' '}
          to short code 84903. Text messages cost your standard network message charge (based on your service provider rates – please check with your provider). Entrants will then receive a free bounce-back message from 62345 informing them that a voluntary donation of £10 to Comic Relief will be taken 60 minutes after the entry text was received, unless they reply to 62345 with the word
          <strong> CANCEL</strong>
          {' '}
          before 60 minutes has passed.
        </li>
        <li>For the avoidance of doubt, opting out of the voluntary donation will not affect your entry into the prize draw. </li>
        <li>The prize draw is open to all residents of the UK, aged 18 or over, except staff members of Comic Relief (the organisation behind Sport Relief), of BBC Group, of any of the prize providers, and their close relatives, or anyone connected with the prize draw. Proof of age, identity and eligibility may be requested. </li>
        <li>If your keyword or short code are invalid, or your text message is received outside the times and dates specified for the prize draw, you will not receive a bounce-back message but may still be charged your standard network message charge. You will not be donating to Sport Relief nor will you have entered the prize draw. </li>
        <li>
          You may enter the prize draw a maximum of four times a day. You will receive a free bounce-back message regarding the donation each time, and if you wish to opt out of the donation you need to respond to each such message received from 62345 with the word
          <strong> CANCEL</strong>
          .
        </li>

        <li>
          There is only ONE prize available to be won:
          {' '}
          <br />
          {' '}
          {' '}
          <br />
          {' '}
          <ul>
            <li>
              <strong>Tickets for the winner and a guest to the London premiere of ‘No Time to Die’ in later in 2020</strong>
              , including a meet-and-greet with Bond Producers Michael G Wilson and Barbara Brocolli (of EON Productions) on the night, and
            </li>
            <li>a Red Carpet experience with photos taken of you by a professional photographer. </li>
            <li>Also includes one night’s accommodation at W Hotel Leicester Square, London, on the night of the premiere (one room, on a double or twin basis, with breakfast for two),</li>
            <li>a Chauffeur-driven experience in the new Land Rover Defender from hotel to the premiere,</li>
            <li>two return train tickets to London from the winner’s nearest major train station</li>
            <li>
              <strong>A Bond Goody Bag to take away with you</strong>
              {' '}
            </li>
          </ul>
        </li>

        <li>The winner will be selected using an independent randomised draw system on Thursday 26th March 2020 and contacted by Comic Relief by telephone that day . If the winner does not pick up the phone that day or the next (27th) after reasonable attempts the BBC and Comic Relief reserve the right to contact the next eligible winner. That winner will have two days to respond after reasonable attempts before moving onto the next eligible winner. If needed, this process will continue until a winner in contacted and confirmed.</li>

        <li>The prizes are as stated. There is no cash alternative and the prizes cannot be sold or transferred in any circumstances. For the avoidance of doubt, unless specified above, no other costs or expenses will be covered by Comic Relief, the BBC, or any of the prize providers.</li>

        <li>Any accessibility or dietary requirements for the winner or their guests need to be disclosed to Comic Relief by within two weeks from notification of win. The winner and their guest may need to adhere to Health & Safety requirements imposed by Comic Relief, Jaguar Land Rover or any venue associated with the prize. </li>

        <li>Comic Relief and BBC’s announcement as to who the winner is, is final. No correspondence relating to the prize draw will be entered into.</li>

        <li>The winner and their guests agree to take part in any reasonable post-prize draw publicity with Comic Relief if required.</li>

        <li>The prize is subject to change. Descriptions of the prize are provided without a warranty of any kind. In no event shall Comic Relief, the BBC or any prize provider be liable for any claim demand or damages caused to an entrant by reason of a description of the prize being inaccurate, incomplete, or for the prize being otherwise in any way different to the entrant’s expectations. Comic Relief reserves the right to disqualify any entries that breach these rules or are in any way fraudulent. Comic Relief reserves the right to cancel the prize draw at any stage, if deemed necessary in its opinion, or if circumstances arise outside its control.</li>

        <li>Comic Relief and their sub-contractors, subsidiaries and/or agencies cannot accept any responsibility whatsoever for any technical failure or malfunction or any other problem with any telephone network or line, system, server, provider or otherwise which may result in any text not being properly received. </li>

        <li>
          Sport Relief is an initiative of Comic Relief, which is the trading name of Charity Projects, a registered charity, number 326568 (England and Wales) SC039730 (Scotland). If you would like to contribute a different amount to Sport Relief, you can donate online on the
          <a href="https://sportrelief.com/donate" target="_blank" rel="noopener noreferrer"> Sport Relief website</a>
          . To find out more about Sport Relief (or Comic Relief and its other initiative Red Nose Day), or if you have questions about this service, please go to www.Comicrelief.com or call the Comic Relief Reception on 020 7820 2000 (Monday to Friday 9:30 – 17:30). Standard geographic charges from landlines and mobiles will apply and calls may be included in your telecom provider’s call package.
        </li>

        <li>
          This prize draw is operated by Comic Relief with broadcast promotion by the BBC. The prize is provided by:
          {' '}
          <br />
          <strong>EON Productions</strong>
          {' '}
          - EON Productions, EON House, 138 Piccadilly, London, W1J 7NR
          {' '}
          {' '}
          <br />
          <strong>Universal Studios</strong>
          {' '}
          – Alex Rowland NBCUniversal, Central Saint Giles, St Giles High Street, London, WC2H 8NU
          {' '}
          {' '}
          <br />
          <strong>Jaguar Land Rover </strong>
          - : Jaguar Land Rover , Abbey Road, Whitley, Coventry CV3 4LF
          {' '}
          {' '}
          <br />
          <strong>BBC</strong>
          {' '}
          - BBC Broadcast Centre, BC3 D6, 201 Wood Lane, London, W12 7TQ
          {' '}
          {' '}
          <br />
          <strong>Sport Relief</strong>
          {' '}
          - Comic Relief 89 Albert Embankment London SE1 7TP
          {' '}
          {' '}
          <br />
          The governing law is that of England and Wales.
        </li>

        <li>The text service, randomised draw and donation service is provided by Fonix Mobile Limited. If you have any queries regarding the service, please contact Fonix at the following email address: care@fonix.com or write to Fonix Mobile Limited, 23 Heddon Street, London, W1B 4BQ or call on the following Helpline Number: 0208 114 7001 (Monday to Friday 9:00 - 17:30).</li>

        <li>
          Your information (mobile phone number and amount of donation) will be collected by Fonix Mobile Limited on behalf Comic Relief and will only be used to process your donation, provide receipts, process any refunds as necessary. If you are a randomly selected as the winner, Fonix will share your information with Comic Relief and the BBC in order for the BBC to contact you to let you know you have won and to administer the prize. Any additional information provided as part of the Gift Aid declaration will only be used for the purpose of Comic Relief’s legitimate interests of claiming Gift Aid and in order to help analyse our income. To find out more about how we will use your information, including how we use it if you sign up to keep up to date about what we support and fundraising news, see our
          <a href="https://www.comicrelief.com/privacy-notice" target="_blank" rel="noopener noreferrer"> privacy policy</a>
          {' '}
          or
          <a href="https://www.comicrelief.com/contact-us" target="_blank" rel="noopener noreferrer"> contact us</a>
          .
        </li>

        <li>
          This prize draw is subject to the BBC's code of conduct for prize draws, found
          <a href="https://www.bbc.com/editorialguidelines/guidance/code-of-conduct" target="_blank" rel="noopener noreferrer"> here</a>
          .
          {' '}
        </li>

        <li>
          The BBC doesn’t believe this unreasonably impacts on your rights and freedoms. The BBC will also keep the winner’s details on file for two years after the Prize in order to comply with its legal and regulatory requirements as broadcast promoter. To find out more about how the BBC will use your data please see
          <a href="https://www.bbc.co.uk/privacy" target="_blank" rel="noopener noreferrer"> here</a>
          .
          {' '}
        </li>
      </ol>

      <p>&nbsp;</p>
      <h2>“Beckham in Miami” Free Prize Draw for Sport Relief </h2>
      <h3>Text to Win Terms and Conditions</h3>
      <ol>
        <li>The prize draw will be open at 6.30pm on 13th March 2020 and close at 2pm on 25th March 2020. </li>

        <li>
          Entry to the prize draw is only by texting the keyword
          <strong> MIAMI</strong>
          {' '}
          to short code 84903. Text messages cost your standard network message charge (based on your service provider rates – please check with your provider). Entrants will then receive a free bounce-back message from 62345 informing them that a voluntary donation of £10 to Comic Relief will be taken 60 minutes after the entry text was received, unless they reply to 62345 with the word
          <strong> CANCEL</strong>
          {' '}
          before 60 minutes has passed.
        </li>

        <li>For the avoidance of doubt, opting out of the voluntary donation will not affect your entry into the prize draw. </li>

        <li>The prize draw is open to all residents of the UK, aged 18 or over, except staff members of Comic Relief (the organisation behind Sport Relief), of BBC Group, of any of the prize providers, and their close relatives, or anyone connected with the prize draw. Proof of age, identity and eligibility may be requested. </li>

        <li>If your keyword or short code are invalid, or your text message is received outside the times and dates specified for the prize draw, you will not receive a bounce-back message but may still be charged your standard network message charge. You will not be donating to Sport Relief nor will you have entered the prize draw. </li>

        <li>
          You may enter the prize draw a maximum of four times a day. You will receive a free bounce-back message regarding the donation each time, and if you wish to opt out of the donation you need to respond to each such message received from 62345 with the word
          <strong> CANCEL</strong>
          .
          {' '}
        </li>

        <li>
          There is only ONE prize available to be won:
          {' '}
          <br />
          {' '}
          {' '}
          <br />
          {' '}
          <ul>
            <li>Meet David Beckham at INTER MIAMI CF Stadium </li>
            <li>Two tickets to watch Inter Miami take on one of our big rivals in the Major League Soccer</li>
            <li>Two pre-game field passes to watch the players warm-up</li>
            <li>A VIP tour for two of the stadium and Training Facility</li>
            <li>A signed shirt of the Inter Miami first team, and</li>
            <li>an MLS Soccer ball signed by David Beckham</li>
          </ul>
        </li>

        <li>The winner will be selected using an independent randomised draw system on Thursday 26th March 2020 and contacted that day live on air by the BBC Radio 1 breakfast show by telephone between 7.30am and 9.30am. If the winner does not pick up the phone after 7 rings or the call goes to voicemail, the BBC will contact the next eligible winner.</li>

        <li>The prizes are as stated. There is no cash alternative and the prizes cannot be sold or transferred in any circumstances. For the avoidance of doubt, unless specified above, no other costs or expenses will be covered by Comic Relief, the BBC, or any of the prize providers.</li>

        <li>Any accessibility or dietary requirements for the winner or their guests need to be disclosed to Comic Relief by within two weeks from notification of win. The winner and their guest may need to adhere to Health & Safety requirements imposed by Comic Relief or any venue associated with the prize. </li>

        <li>Comic Relief and BBC’s announcement as to who the winner is, is final. No correspondence relating to the prize draw will be entered into.</li>

        <li>The winner and their guests agree to take part in any reasonable post-prize draw publicity with Comic Relief if required.</li>

        <li>The prize is subject to change. Descriptions of the prize are provided without a warranty of any kind. In no event shall Comic Relief, the BBC or any prize provider be liable for any claim demand or damages caused to an entrant by reason of a description of the prize being inaccurate, incomplete, or for the prize being otherwise in any way different to the entrant’s expectations. Comic Relief reserves the right to disqualify any entries that breach these rules or are in any way fraudulent. Comic Relief reserves the right to cancel the prize draw at any stage, if deemed necessary in its opinion, or if circumstances arise outside its control. </li>

        <li>Comic Relief and their sub-contractors, subsidiaries and/or agencies cannot accept any responsibility whatsoever for any technical failure or malfunction or any other problem with any telephone network or line, system, server, provider or otherwise which may result in any text not being properly received. </li>

        <li>
          Sport Relief is an initiative of Comic Relief, which is the trading name of Charity Projects, a registered charity, number 326568 (England and Wales) SC039730 (Scotland). If you would like to contribute a different amount to Sport Relief, you can donate online on the Sport Relief
          <a href="https://sportrelief.com/donate" target="_blank" rel="noopener noreferrer"> website</a>
          . To find out more about Sport Relief (or Comic Relief and its other initiative Red Nose Day), or if you have questions about this service, please go to www.Comicrelief.com or call the Comic Relief Reception on 020 7820 2000 (Monday to Friday 9:30 – 17:30). Standard geographic charges from landlines and mobiles will apply and calls may be included in your telecom provider’s call package.
        </li>

        <li>
          This prize draw is operated by Comic Relief with broadcast promotion by the BBC. The prize is provided by:
          {' '}
          <br />
          Inter Miami CF, Inter Miami CF Stadium, Fort Lauderdale, Florida, USA
          {' '}
          {' '}
          <br />
          Soho House, 76 Dean St, Soho, London W1D 3SQ
          {' '}
          {' '}
          <br />
          BBC - BBC Broadcast Centre, BC3 D6, 201 Wood Lane, London, W12 7TQ
          {' '}
          {' '}
          <br />
          Sport Relief - Comic Relief 89 Albert Embankment London SE1 7TP
          {' '}
          {' '}
          <br />
          The governing law is that of England and Wales.
          {' '}
          {' '}
          <br />
        </li>

        <li>The text service, randomised draw and donation service is provided by Fonix Mobile Limited. If you have any queries regarding the service, please contact Fonix at the following email address: care@fonix.com or write to Fonix Mobile Limited, 23 Heddon Street, London, W1B 4BQ or call on the following Helpline Number: 0208 114 7001 (Monday to Friday 9:00 - 17:30</li>

        <li>
          Your information (mobile phone number and amount of donation) will be collected by Fonix Mobile Limited on behalf Comic Relief and will only be used to process your donation, provide receipts, process any refunds as necessary. If you are a randomly selected as the winner, Fonix will share your information with Comic Relief and the BBC in order for the BBC to contact you to let you know you have won and to administer the prize. Any additional information provided as part of the Gift Aid declaration will only be used for the purpose of Comic Relief’s legitimate interests of claiming Gift Aid and in order to help analyse our income. To find out more about how we will use your information, including how we use it if you sign up to keep up to date about what we support and fundraising news, see our
          <a href="https://www.comicrelief.com/privacy-notice" target="_blank" rel="noopener noreferrer"> privacy policy</a>
          {' '}
          or
          <a href="https://www.comicrelief.com/contact-us" target="_blank" rel="noopener noreferrer"> contact us</a>
          .
        </li>
        <li>
          This prize draw is subject to the BBC's code of conduct for prize draws, found
          <a href="https://www.bbc.com/editorialguidelines/guidance/code-of-conduct" target="_blank" rel="noopener noreferrer"> here</a>
          .
          {' '}
        </li>

        <li>
          The BBC will receive the winner’s name and contact details in order to call them as part of the Radio 1 Breakfast show. The BBC relies on its legitimate interests in increasing our audience engagement with broadcast prize draws, to provide interesting on-air content and assisting with fundraising for our charity partners, such as Comic Relief. The BBC doesn’t believe this unreasonably impacts on your rights and freedoms. The BBC will also keep the winner’s details on file for two years after the Prize in order to comply with its legal and regulatory requirements as broadcast promoter. To find out more about how the BBC will use your data please see
          <a href="https://www.bbc.co.uk/privacy" target="_blank" rel="noopener noreferrer"> here</a>
          .
        </li>
      </ol>
    </Container>
  </Layout>
);

export default sms;
